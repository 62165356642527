<template>

    <!-- 本地词库 -->
    <div class="lexicon">
        <!-- 本地词库顶部 -->
        <div class="lexicon-top">
            <div class="lexicon-top-text">
                本地词库
            </div>
        </div>

        <!-- 本地词库添加按钮 -->
        <div class="lexicon-add-button" @click="showAddLexiconDialog('')">
            <div class="lexicon-add-logo">+</div>
            <div class="lexicon-add-text">添加词库</div>
        </div>

        <!-- 本地词库内容列表 -->
        <div class="lexicon-content">
            <!-- 条目 -->
            <div class="lexicon-entry">
                <div style="flex: 2; padding-left: 29px;">名称</div>
                <div style="flex: 1; text-align: center;">备注</div>
                <div style="flex: 2; text-align: center;">词库</div>
                <div style="flex: 1; text-align: center;">操作</div>
            </div>
            <!-- 列表 -->
            <div class="lexicon-list" v-for="(item, index) in dataList" :key="index">
                <div style="display: flex; flex: 2; padding-left: 29px;">
                    <div class="lexicon-item-img"></div>
                    <div style="margin-left: 10px;">
                        <div class="lexicon-type-name">{{ item.name }}</div>
                        <div class="lexicon-type-info">{{ item.tradeName }}</div>
                    </div>
                </div>
                <div style="flex: 1; text-align: center;">
                    <div class="lexicon-type-name">{{ item.remarks }}</div>
                </div>
                <div class="lexicon-type-name" style="flex: 2; text-align: center;">
                    <span class="lexiconWordTypeBtn" style="margin-right: 20px;" @click="toWordList(item.id, 0)">违规词{{
                        '(' + item.sensitiveNum + ')'
                        }}</span>
                    <span class="lexiconWordTypeBtn" style="margin-right: 20px;" @click="toWordList(item.id, 2)">白名单{{
                        '(' + item.whiteNum + ')'
                        }}</span>
                    <span class="lexiconWordTypeBtn" @click="toWordList(item.id, 1)">关键词{{ '(' + item.cruxNum + ')'
                        }}</span>
                </div>
                <div style="flex: 1; display: flex; justify-content: center;">
                    <el-button class="lexicon-update-button" @click="showAddLexiconDialog(item.id)">编辑</el-button>
                </div>

            </div>

            <el-pagination style="text-align: center; margin-top: 10px" @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100, 500]"
                :page-size="pageSize" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>

        <!-- 添加/修改词库表单 -->
        <div>
            <el-dialog title="添加词库" :visible.sync="dialogFormVisible" width="400px" :close-on-click-modal="false">
                <div class="dialogClass">
                    <el-form :model="addForm" :rules="dataRule" ref="addForm" label-width="100px">
                        <div style="margin-top: 24px;">
                            <el-form-item class="add-form-item" label="名称" prop="name">
                                <el-input v-model="addForm.name" placeholder="请输入词库名称" style="width: 220px;"
                                    size="small"></el-input>
                            </el-form-item>
                            <el-form-item label="行业" prop="tradeIdArr">
                                <el-cascader v-model="addForm.tradeIdArr" :options="tradeTreeList"
                                    :props="{ checkStrictly: true, expandTrigger: 'click', value: 'id', label: 'name' }"
                                    filterable placeholder="请选择行业" @change="changeTradeHandle" ref="tradeCascader"
                                    style="width: 220px;" size="small">
                                </el-cascader>
                            </el-form-item>
                            <el-form-item class="add-form-item" label="备注" prop="remarks">
                                <el-input v-model="addForm.remarks" placeholder="请输入备注" style="width: 220px;"
                                    size="small"></el-input>
                            </el-form-item>
                        </div>

                        <div class="add-form-button">
                            <el-button class="cancel" @click="dialogFormVisible = false">取消</el-button>
                            <el-button class="addYes" @click="submit">确认添加</el-button>
                        </div>
                    </el-form>
                </div>

            </el-dialog>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            dataForm: {
                keyword: ''
            },
            tradeTreeList: [],
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            dialogFormVisible: false,
            addForm: {
                id: "",
                tradeIdArr: [],
                name: "",
                tradeId: "",
                remarks: "",
            },
            dataRule: {
                name: [
                    { required: true, message: "词库名称不能为空", trigger: "blur" },
                ],
                tradeIdArr: [
                    { required: true, message: "行业不能为空", trigger: "blur" },
                ]
            },
            addFormList: [
                { value: 'chibo', label: '吃播' },
                { value: 'lvxing', label: '旅行' },
                { value: 'tandian', label: '探店' }
            ]
        }
    },
    created() {
        this.getTradeTreeList();
        this.getDataList();
    },
    methods: {
        toWordList(lexiconId, wordType) {
            this.$emit("child-event", 'lexiconUpdate', this.cikuList[index].cikuTypeInfo)
        },
        // 显示
        showAddLexiconDialog(id) {
            this.dialogFormVisible = true;
            this.addForm.tradeIdArr = [];
            this.$nextTick(() => {
                this.$refs["addForm"].resetFields();
                this.addForm.id = id;
                if (this.addForm.id) {
                    this.$httpBack.lexicon.info({ id }).then(res => {
                        if (res.code == 0) {
                            this.addForm = res.data;
                            this.addForm.tradeIdArr = res.data.tradeId;
                            console.log(this.addForm.tradeIdArr);
                        }
                    })
                }
            });
        },
        // 选择行业回调
        changeTradeHandle() {
            // 关闭级联列表下拉
            this.$refs.tradeCascader.dropDownVisible = false;
        },
        // 获取行业列表树形
        getTradeTreeList() {
            this.tradeTreeList = [];
            this.$httpBack.trade.listTree({}).then((res) => {
                if (res && res.code === 0) {
                    this.tradeTreeList = res.data;
                }
            });
        },
        // 添加/编辑词库
        submit() {
            console.log(this.addForm)
            this.$refs["addForm"].validate((valid) => {
                console.log(valid)
                if (valid) {
                    let requestData = JSON.parse(JSON.stringify(this.addForm));
                    if (requestData.tradeIdArr instanceof Array) {
                        requestData.tradeId = requestData.tradeIdArr[requestData.tradeIdArr.length - 1];
                    } else {
                        requestData.tradeId = requestData.tradeIdArr;
                    }


                    if (requestData.id) {
                        // 修改
                        this.$httpBack.lexicon.update(requestData).then(res => {
                            if (res.code == 0) {
                                this.$message.success(res.msg);
                                this.dialogFormVisible = false;
                                this.search();
                            }
                        });
                    } else {
                        // 新增
                        this.$httpBack.lexicon.save(requestData).then(res => {
                            if (res.code == 0) {
                                this.$message.success(res.msg);
                                this.dialogFormVisible = false;
                                this.search();
                            }
                        });
                    }
                }
            })
        },

        // 查询
        search() {
            this.pageIndex = 1;
            this.getDataList();
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;
            this.dataForm.page = this.pageIndex;
            this.dataForm.limit = this.pageSize;

            this.$httpBack.lexicon.list(this.dataForm).then((res) => {
                if (res && res.code === 0) {
                    this.dataList = res.data.list;
                    this.totalCount = res.data.totalCount;
                } else {
                    this.dataList = [];
                    this.totalCount = 0;
                }
                this.dataListLoading = false;
            });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
        },
        // 删除
        deleteHandle(id) {
            this.$confirm(`确定要进行删除吗？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$http.lexicon.delete({
                    id,
                }).then((res) => {
                    if (res && res.code === 0) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                            duration: 1500,
                            onClose: () => {
                                this.getDataList();
                            },
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },

    }
}
</script>

<style scoped>
.lexiconWordTypeBtn {
    cursor: pointer;
    color: #0077FF;
}

.dialogClass {
    padding-bottom: 27px;
}

/* 顶部内容 */
.lexicon-top {
    width: calc(100% - 28px);
    border-bottom: 1px solid #DCE0E7;
    margin-left: 14px;
}

.lexicon-top-text {
    color: #2E3742;
    font-size: 16px;
    margin-bottom: 13px;
}

/* 本地词库添加按钮 */
.lexicon-add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94px;
    height: 32px;
    background-color: #0077FF;
    margin: 15px 0px 0px 14px;
}

.lexicon-add-button:hover {
    cursor: pointer;
}

.lexicon-add-logo {
    font-size: 18px;
    color: #FFFFFF;
    margin-right: 7px;
}

.lexicon-add-text {
    color: #FFFFFF;
    font-size: 13px;
}

/* #region 本地词库内容列表 */
.lexicon-content {
    margin: 16px 14px 0px 14px;
}

/* 条目 */
.lexicon-entry {
    display: flex;
    align-items: center;
    height: 28px;
    background-color: #F5F7F9;
    color: #2E3742;
    font-size: 12px;
    border-radius: 4px;
}


/* 列表 */
.lexicon-list {
    display: flex;
    height: 63px;
    align-items: center;
    border-bottom: 1px solid #DCE0E7;
}

.lexicon-item-img {
    width: 48px;
    height: 38px;
    background-color: skyblue;
}

.lexicon-type {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.lexicon-type-name {
    color: #2E3742;
    font-size: 14px;
}

.lexicon-type-info {
    color: #677583;
    font-size: 13px;
}

.lexicon-ciku {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    color: #2E3742;
    font-size: 14px;
}

.lexicon-update-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 24px;
    font-size: 13px;
    color: #2E3742;
    border-radius: 4px;
    border: 1px solid #B4BCCA;
}

.lexicon-update-button:hover {
    cursor: pointer;
}

/* #region 添加词库表单 */
/* .add-form-item {
    display: flex;
    align-items: center;
} */

.add-form-button {
    display: flex;
    justify-content: center;
    margin-top: 30px;

}

.cancel {
    width: 110px;
    height: 40px;
    color: #2E3742;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #B4BCCA;
    margin-right: 20px;
}

.addYes {
    width: 110px;
    height: 40px;
    color: #FFFFFF;
    background-color: #0077FF;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #B4BCCA;
}

/* #endregion */

/* 修改element-ui默认样式 */
::v-deep .el-dialog__body {
    padding: 0;
}

::v-deep .el-dialog__header {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .el-dialog__headerbtn {
    top: 14px;
    right: 13px;
}

::v-deep .el-dialog__title {
    font-size: 16px;
    color: #2E3742;
    margin-top: 25px;
}

::v-deep .el-button+.el-button {
    margin: 0;
}

/* ::v-deep .el-form-item {
    margin: 0px;
}

::v-deep .el-form-item__label {
    color: #2E3742;
    font-size: 14px;
    margin: 0px 13px 0px 42px;
    padding: 0;
}

::v-deep .el-input__inner {
    width: 240px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #CFD4DB;
} */
</style>